


.main-container {
  width: 100vw;
  height: 100vh;
  padding: 0 4rem;

}

.main-container > div {
  height: 100%;
  z-index: 9000;
  background: rgba(128,128,128,0.5);
  padding: 1rem 2rem;
  overflow-y: auto;
}

.main-container > div::-webkit-scrollbar { width: 0 !important }
.main-container > div { overflow: -moz-scrollbars-none; }
.main-container > div { -ms-overflow-style: none; }


// For a Desktop view
@include media-breakpoint-up(lg) {
  .main-container > div {
    margin: 0 10rem;
  }
}
@include media-breakpoint-between(sm, md) {
  .main-container > div {
    margin: 0;
  }
}

// For a Mobile view
@include media-breakpoint-down(xs) {
  .main-container {
    padding: 0;

  }
  .main-container > div {
    padding: 1rem 1rem;
  }
}