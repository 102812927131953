.video-container {
  background-size: contain;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  z-index: -100;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.fullscreen-video {
  min-width: 100%;
  min-height: 100%;
}


