
// For a Desktop view
@include media-breakpoint-up(lg) {
  .meditation-list-item {
    flex-basis: 200px;
  }
}
@include media-breakpoint-between(sm, md) {
  .meditation-list-item {
    flex-basis: 200px;
  }
}

// For a Mobile view
@include media-breakpoint-down(xs) {
  .meditation-list-item {
    flex-basis: 200px;
  }
}

.meditation-list-item {
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-template-rows: 1fr 3fr;
  color: #fff;
  border-radius: 5px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 10px;
  flex-shrink: 1;
  transition: all .3s ease-in-out;
}

.meditation-list-item:hover {
  text-decoration: none;
  color: #fff;
  transform: scale(1.05);
}


.meditation-title {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 3;
  text-align: center;
  background: rgba(0,0,0,0.7);
  margin: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 15px 5px;
}

.meditation-item-duration {
  grid-column-start: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,0.7);
  border-radius: 15px;
  padding: 4px;
  margin: 4px;
}

.action-icon {
  grid-column-start: 1;
  grid-column-end: 3;
  align-self: center;
  justify-self: center;
}

