
.meditation-list-container > div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}


// For a Desktop view
@include media-breakpoint-up(lg) {

  .meditation-list-container  {
    margin: 1rem 5rem;
  }
}
@include media-breakpoint-between(sm, md) {

  .meditation-list-container  {
    margin: 1rem 2rem;
  }
}

// For a Mobile view
@include media-breakpoint-down(xs) {

  .meditation-list-container  {
    margin: 1rem 0rem;
  }
}

