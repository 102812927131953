.social-icons {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.social-icons > div {
  display: flex;
  flex-direction: row;
  flex-basis: 0;
  flex-grow: 1;
  justify-content: space-around;
  margin-top: 3rem;

}

.social-icons .fa-stack {
  color: #fff;
}
.social-icons .fa-stack:hover {
    opacity: 0.6;
}