@font-face {
  font-family: 'Circular';
  src: url('./fonts/CircularStd-Book.eot');
  src: url('./fonts/CircularStd-Book.woff2') format('woff2'),
  url('./fonts/CircularStd-Book.woff') format('woff'),
  url('./fonts/CircularStd-Book.ttf') format('truetype'),
  url('./fonts/CircularStd-Book.svg#CircularStd-Book') format('svg'),
  url('./fonts/CircularStd-Book.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Circular-Bold';
  src: url('./fonts/CircularStd-Bold.eot');
  src: url('./fonts/CircularStd-Bold.woff') format('woff'),
url('./fonts/CircularStd-Bold.ttf') format('truetype'),
url('./fonts/CircularStd-Bold.svg#CircularStd-Bold') format('svg'),
url('./fonts/CircularStd-Bold.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Circular-Black';
  src: url('./fonts/CircularStd-Black.eot');
src: url('./fonts/CircularStd-Black.woff') format('woff'),
url('./fonts/CircularStd-Black.ttf') format('truetype'),
url('./fonts/CircularStd-Black.svg#CircularStd-Black') format('svg'),
url('./fonts/CircularStd-Black.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}
