.homepage {
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: space-around;
}

.homepage-title {
  margin: 5rem;
  text-align: center;
}

.homepage-title img {
  width: 100px;
}

.homepage-title h1 {
  font-family: Circular-Bold, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif;
  text-transform: lowercase;
  letter-spacing: 1.5rem;
  margin: 1rem;
}

.homepage-hr {
  border: 0;
  width: 20rem;
  color: #fff;
  background-color: #fff;
  height: 3px;
}

.homepage-btn-container {
  display: flex;
  justify-content: center;
  margin: 0;
}

// For a Mobile view
@include media-breakpoint-down(xs) {

  .homepage-title {
    margin: 3rem 1rem;
    text-align: center;
  }
  .homepage-title h1 {
    letter-spacing: 0.8rem;
    margin: 0.5rem;
  }
  .homepage-hr {
    width: 15rem;
    height: 2px;
  }
  .homepage-title h5 {
    font-size: 1.1rem;
  }

}
