.audio-player-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.meditation-audio-title {
  margin-top: -10vh;
}

.close-btn-audio-player {
  display: flex;
  align-self: flex-end;
  margin-right: -1rem;
  color: #fff;
  background: transparent;
  border: 0;
}

.close-btn-audio-player:hover {
  color: $danger;
  opacity: 0.6;
}

.audio-control-btn-container svg {
  position: absolute;
  top: calc((100vh - 180px) / 2);
  left: calc((100vw - 180px) / 2);

}
.audio-control-btn-container button {
  position: absolute;
  top: calc((100vh - 120px) / 2);
  left: calc((100vw - 120px) / 2);
  border: 0;
  background: $primary;
  color: #fff;
  border-radius: 50%;
  width: 120px;
  height: 120px;

}


/* Circular Progress */
:root {
  --progress-bg-color: #ddd;
  --progress-fg-color: #C3C3C3;
}

.CircularProgress-Bg,
.CircularProgress-Fg {
  fill: none;
}

.CircularProgress-Bg {
  stroke: var(--progress-bg-color);
}

.CircularProgress-Fg {
  transition: stroke-dashoffset .5s ease-in-out;
  stroke: var(--progress-fg-color);
}
