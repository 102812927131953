.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: .5;
}

.modal-wrapper {
  display: flex;
  position: fixed;
  padding: .5rem;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-box {
  z-index: 100;
  background: white;
  position: relative;
  margin: auto;
  border-radius: 3px;
  pointer-events: none;
  color: $modal-color;
}

.modal-inner{
  pointer-events: auto;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  padding: 1.5rem 1.5rem .5rem 1.5rem;
}

.modal-header .modal-title {
  font-weight: 700;
  font-size: 1.2rem;
}

.modal-close-button {
  padding: 1rem;
  background: white;
  margin: -1rem -1rem -1rem auto;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  color: $modal-color;
  opacity: .3;
  cursor: pointer;
  border: none;
  outline: 0 !important;
}

.modal-close-button:hover {
  opacity: .8;
  text-decoration: none;
}

.modal-btn-container {
  display: flex;
  justify-content: center;
}

.modal-btn-container button, .modal-btn-container a {
  margin: 0.5rem 2rem;
}

.modal-body {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
}



// For a Desktop view
@include media-breakpoint-up(lg) {
  .modal-box {
    min-width: 30%;
    max-width: 500px;
  }
}
@include media-breakpoint-between(sm, md) {
  .modal-box {
    min-width: 40%;
    max-width: 400px;
  }

}
  // For a Mobile view
  @include media-breakpoint-down(xs) {
    .modal-box {
      min-width: 85%;
      max-width: 95%;
    }
  }

